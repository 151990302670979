/* eslint-disable max-len */
import { useCallback, useState } from 'react';
import {
  AccessorFn, ColumnFiltersState, createColumnHelper, DeepKeys, DeepValue, DisplayColumnDef, IdentifiedColumnDef,
} from '@tanstack/react-table';
import { useStore } from 'zustand';
import { IPaginationMutableState } from './PaginationV8';
import { ICustomFilter, PageableColumnDefV8, TableColumnDefV8 } from './ReactTableV8';
import { useModules } from '../../providers/ModuleProvider';
import { Module } from '../../types/AccessTypes';
import { useTableStoreV8 } from './TableStoreV8';

export enum Direction {
  none = 0,
  asc = 1,
  desc = 2
}

export interface ISorting {
  property:string,
  direction:Direction,
}

const directionToSearchString = (direction:Direction) => {
  switch (direction) {
  case Direction.asc:
    return 'asc';
  case Direction.desc:
    return 'desc';
  case Direction.none:
  default:
    return '';
  }
};

export const convertSortingToSortBy = (sorting:ISorting[]) => {
  if (!sorting.length) return null;
  let sortBy = '';
  sorting.forEach((s) => {
    if (s.direction === Direction.none) return;
    const direction = directionToSearchString(s.direction);
    sortBy += `${sortBy.length ? ',' : ''}${s.property}${direction ? ` ${direction}` : ''}`;
  });
  return sortBy;
};

export interface IPagedTableFilter<T> {
  pageableQuery:IPaginationMutableState&{sortBy?:string}&T,
  setPage: (newPaging:IPaginationMutableState) => void,
  setQuery: (newQuery:T) => void,
  setSorting: (newSorting:ISorting[]) => void,
  sorting:ISorting[],
  appendQuery: (newQuery:T) => void,
  isFiltered:boolean,
  reset: () => void,
}

export const useAllVulnerabilityModuleOptions = () => {
  const { customerModules } = useModules();

  const modules = customerModules
    .filter((module) => (
      module.id > 0
      && module.id !== Module.customerAdmin
      && (module.jobTypeKeys?.includes('scan') || module.id === Module.assessment)
    ));

  return modules.map((m) => m.id);
};

const nonFilterKeys = ['page', 'pageSize', 'sortBy'];

const queryAsFilters = <T extends object, >(query:T) => {
  const filters:ColumnFiltersState = [];
  const queryAsObject = query as { [key: string]: unknown };
  Object.keys(queryAsObject).forEach((k) => {
    if (nonFilterKeys.includes(k)) return;
    filters.push({ id: k, value: queryAsObject[k] });
  });
  return filters;
};

const filtersAsQuery = <T, >(filters:ColumnFiltersState) : T|null => (!filters.length
  ? null
  : filters.reduce((acc, filter) => {
    const curr = { ...acc };
    curr[filter.id] = filter.value;
    return curr;
  }, {} as Record<string, unknown>) as T);

/**
   * This is a hybrid solution integrating the paged query/paging/sort state
   * with the store used for ReactTableV8 filtering. Takes care of persisting
   * state for a given location hash when navigating back and forth between
   * routes.
   */
export const usePagedTableFilter = <T extends object, > (
  id:string,
  emptyQuery:T,
  initialQuery?:T,
  initialSorting?:ISorting[],
  appendSortingFilters?:(sorting:ISorting[], query:T) => T | undefined | null,
):IPagedTableFilter<T> => {
  const { store } = useTableStoreV8(id, {
    initialFilters: queryAsFilters({
      ...emptyQuery,
      ...initialQuery,
    }),
  });
  const tableState = useStore(store);

  const [queryState, setQueryState] = useState<T>(
    filtersAsQuery(tableState.filters) ?? {
      ...emptyQuery,
      ...initialQuery,
    },
  );

  const [sortingState, setSortingState] = useState<ISorting[]>(
    tableState.sorting.length ? tableState.sorting : initialSorting ?? [],
  );

  const setSorting = useCallback((sorting:ISorting[]) => {
    tableState.setSorting(sorting);
    setSortingState(sorting);
  }, [tableState]);

  const setQuery = useCallback((query:T) => {
    tableState.setFilters(queryAsFilters(query));
    setQueryState(query);
  }, [tableState]);

  const appendQuery = useCallback((newQuery:T) => {
    setQuery({
      ...queryState,
      ...newQuery,
    });
    tableState.setPagination({
      ...tableState.pagination,
      pageIndex: 0,
    });
  }, [queryState, setQuery, tableState]);

  const pageableQuery:IPaginationMutableState&{sortBy?:string}&T = {
    ...queryState,
    ...{
      page: tableState.pagination.pageIndex,
      pageSize: tableState.pagination.pageSize,
    },
    ...appendSortingFilters ? appendSortingFilters(sortingState, queryState) : {},
  };

  const sortBy = convertSortingToSortBy(sortingState);
  if (sortBy) pageableQuery.sortBy = sortBy;

  return {
    pageableQuery: {
      ...pageableQuery,
      page: pageableQuery.page + 1,
    },
    setPage: (newPaging:IPaginationMutableState) => {
      if (newPaging.page === 0) {
        throw new Error(`Invalid page: ${newPaging.page}`);
      }
      tableState.setPagination({
        pageSize: newPaging.pageSize,
        pageIndex: newPaging.page - 1,
      });
    },
    setQuery,
    setSorting,
    sorting: sortingState,
    appendQuery,
    isFiltered: JSON.stringify(emptyQuery) !== JSON.stringify(queryState),
    reset: () => {
      const newQuery = emptyQuery as T;
      setQuery(newQuery);
    },
  };
};

export const createPageableColumnHelper = <TData, >() => {
  const columnHelper = createColumnHelper<TData>();

  return {
    accessor: <
      TAccessor extends AccessorFn<TData> | DeepKeys<TData>,
      TValue extends TAccessor extends AccessorFn<TData, infer TReturn> ? TReturn : TAccessor extends DeepKeys<TData> ? DeepValue<TData, TAccessor> : never,
      TFilterValue,
    >(
      accessor: TAccessor,
      column: TAccessor extends AccessorFn<TData> ? DisplayColumnDef<TData, TValue> : IdentifiedColumnDef<TData, TValue> | TableColumnDefV8<TData, TValue>,
      filters?: ICustomFilter<TFilterValue>,
    ): PageableColumnDefV8<TData, TFilterValue> => {
      const columnDef = columnHelper.accessor(
        accessor,
        column,
      );

      const col:PageableColumnDefV8<TData, TFilterValue> = {
        ...columnDef as TAccessor & {id:string, accessorKey:string},
        customFilter: filters,
      };

      return col;
    },
    display: columnHelper.display,
    group: columnHelper.group,
  };
};
