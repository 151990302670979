import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useStore } from 'zustand';
import { Link } from 'react-router-dom';
import { Icon } from '@ailibs/feather-react-ts';
import { ISoftware } from './Types';
import { TableFromArray } from '../../common/table/TableFromArray';
import { TableColumnDefV8 } from '../../common/table';
import { columnsToVisibilityState, useTableStoreV8 } from '../../common/table/TableStoreV8';
import { Direction } from '../../common/table/PagedResultFilter';
import { ClipboardCopy } from '../../components/ClipboardCopy';

/**
 * IDs and URLs provided by ChatGPT, all are verified to work with some example vulnerabilities.
 */
export const getSecurityAdvisoryUrl = (advisoryId: string): string | null => {
  // Regular expressions for known advisory ID formats
  const patterns: { [key: string]: { regex: RegExp, url: (id: string) => string } } = {
    'Microsoft KB': {
      // https://support.microsoft.com/help/5044285
      regex: /^(KB)?\d{7}$/i,
      url: (id: string) => `https://support.microsoft.com/help/${id.replace('KB', '')}`,
    },
    'Ubuntu USN': {
      // e.g., https://ubuntu.com/security/notices/USN-6680-1
      regex: /^USN-\d{4,}-\d+$/i,
      url: (id: string) => `https://ubuntu.com/security/notices/${id}`,
    },
    'Debian DSA': {
      // e.g., https://www.debian.org/security/DSA-5786-1
      regex: /^DSA-\d{4}-\d+$/i,
      url: (id: string) => `https://www.debian.org/security/${id}`,
    },
    'Red Hat RHSA': {
      // e.g., https://access.redhat.com/errata/RHSA-2024:8176
      regex: /^RHSA-\d{4}:\d{4,5}$/i,
      url: (id: string) => `https://access.redhat.com/errata/${id}`,
    },
    'Cisco SA': {
      // e.g., https://sec.cloudapps.cisco.com/security/center/content/CiscoSecurityAdvisory/cisco-sa-vlan-dos-27Pur5RT
      regex: /^Cisco-SA-\d{8}-\w+$/i,
      url: (id: string) => `https://sec.cloudapps.cisco.com/security/center/content/CiscoSecurityAdvisory/${id}`,
    },
    // https://www.mozilla.org/en-US/security/advisories/mfsa2024-53/
    'Mozilla MFSA': {
      regex: /^MFSA-\d{2}-\d{2}$/i,
      url: (id: string) => `https://www.mozilla.org/en-US/security/advisories/${id}`,
    },
    // e.g., https://www.oracle.com/security-alerts/cpujan2023.html
    'Oracle CPU': {
      regex: /^CPU(?:JAN|APR|JUL|OCT)\d{4}$/i,
      url: (id: string) => `https://www.oracle.com/security-alerts/${id.toLowerCase()}.html`,
    },
    // e.g., https://support.apple.com/en-us/HT213531
    'Apple HT': {
      regex: /^HT\d{6}$/i,
      url: (id: string) => `https://support.apple.com/en-us/${id}`,
    },
  };

  const keys = Object.keys(patterns);
  for (let i = 0; i < keys.length; i += 1) {
    const { regex, url } = patterns[keys[i]];
    if (regex.test(advisoryId)) {
      return url(advisoryId);
    }
  }

  // If the ID doesn't match any known format, return null
  return null;
};

export const SoftwareTable = ({
  vulnerabilityId,
  software,
}:{
  vulnerabilityId:number,
  software:ISoftware[]
}) => {
  const columnHelper = useMemo(() => createColumnHelper<ISoftware>(), []);

  const columns = useMemo<TableColumnDefV8<ISoftware, unknown>[]>(() => ([
    columnHelper.accessor('name', {
      header: 'Name',
      filterFn: 'weakEquals',
      enableColumnFilter: false,
    }),
    columnHelper.accessor('vendor', {
      header: 'Vendor',
      filterFn: 'weakEquals',
      enableColumnFilter: false,
    }),
    columnHelper.accessor('version', {
      header: 'Version',
      filterFn: 'weakEquals',
      enableColumnFilter: false,
    }),
    columnHelper.accessor('diskPath', {
      header: 'Disk path',
      filterFn: 'weakEquals',
      enableColumnFilter: false,
      cell: ({ getValue }) => <code>{getValue()}</code>,
    }),
    columnHelper.accessor('securityAdvisoryId', {
      header: 'Security advisory',
      filterFn: 'weakEquals',
      enableColumnFilter: false,
      cell: ({ getValue }) => {
        const value = getValue();
        if (!value) {
          return null;
        }

        const externalUri = getSecurityAdvisoryUrl(value);

        return externalUri
          ? (
            <Link target="_blank" to={externalUri}>
              {value}
              <Icon name="external-link" size="16" className="ms-1" />
            </Link>
          ) : <ClipboardCopy>{value}</ClipboardCopy>;
      },
    }),
  ] as TableColumnDefV8<ISoftware, unknown>[]), [columnHelper]);

  const { store: tableStore } = useTableStoreV8(
    `softwareTable-${vulnerabilityId}`,
    {
      visibilityState: columnsToVisibilityState(columns),
      pageSize: 50,
      initialSorting: [
        { property: 'name', direction: Direction.asc },
      ],
    },
  );

  const tableState = useStore(tableStore);

  return (
    <TableFromArray
      data={software}
      columnDefs={columns}
      state={tableState}
    />
  );
};
