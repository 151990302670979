import React, { useMemo, useState } from 'react';
import {
  Button, Modal, OverlayTrigger, Spinner, Tooltip,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { useStore } from 'zustand';
import { EntityType, IHaveEntityId } from '../types/EntityTypes';
import { useApi } from '../query/GenericQuery';
import { useEntityTypeAsText } from '../utils/TranslationUtils';
import { PagedResult } from '../types/PagedResult';
import { PageableColumnDefV8, TableCellDateFormattedV8 } from '../common/table';
import { columnsToVisibilityState, useTableStoreV8 } from '../common/table/TableStoreV8';
import { createPageableColumnHelper, Direction, usePagedTableFilter } from '../common/table/PagedResultFilter';
import { TableFromPageable } from '../common/table/TableFromPageable';

interface IProps extends IHaveEntityId {
  show: boolean,
  handleClose: () => void,
  filterByObjectTypes?: string[]|string|undefined
}

export const changeTypesAsStrings = [
  'added',
  'modified',
  'deleted',
] as const;
export type ChangeType = typeof changeTypesAsStrings[number];

interface IChangelog {
  timestamp:Date,
  changeType:ChangeType,
  objectTypeName:string,
  key?:string,
  valueFrom?:string,
  valueTo?:string,
  upn?:string,
  appId?:string,
  tenantId?:string,
  entityType?:string
}

interface IChangeLogListOptions {
  objectTypes?: string[],
  key?: string,
  valueFrom?: string,
  valueTo?: string,
  changeType?: string,
  account?: string,
  changeTypes?: string[]
}

export const EntityChangelogModal = (props: IProps) => {
  const {
    show, handleClose, entityId, entityType, filterByObjectTypes,
  } = props;

  const options:IChangeLogListOptions = {
    objectTypes: typeof filterByObjectTypes === 'string' ? [filterByObjectTypes] : filterByObjectTypes,
  };

  const pagedFilter = usePagedTableFilter(
    'changelog',
    options,
    options,
    [
      { property: 'timestamp', direction: Direction.desc },
    ],
  );

  const { data: pagedChanges } = useApi<PagedResult<IChangelog>>(
    show && `changelog/${encodeURIComponent(entityType)}/${encodeURIComponent(entityId)}`,
    pagedFilter.pageableQuery,
  );

  const columnHelper = createPageableColumnHelper<IChangelog>();

  const columns = useMemo(() => (
    [
      columnHelper.accessor(
        'timestamp',
        {
          header: 'Timestamp',
          cell: ({ getValue }) => TableCellDateFormattedV8(getValue()),
          enableColumnFilter: false,
        },
        {
          filterPropertyName: 'timestamp',
          sortPropertyName: 'timestamp',
        },
      ),
      columnHelper.accessor(
        'changeType',
        {
          header: 'Change type',
          enableSorting: false,
        },
        {
          filterPropertyName: 'changeTypes',
          filterFn: (values:string[]) => {
            pagedFilter.appendQuery({
              changeTypes: values,
            });
          },
          selectOptions: changeTypesAsStrings,
          supportMultiSelect: true,
        },
      ),
      columnHelper.accessor(
        'objectTypeName',
        {
          header: 'Type',
          enableSorting: false,
          defaultHidden: true,
        },
        {
          filterPropertyName: 'objectTypes',
          filterFn: (values:string[]) => {
            pagedFilter.appendQuery({
              objectTypes: values,
            });
          },
        },
      ),
      columnHelper.accessor(
        'key',
        {
          header: 'Key',
          enableSorting: false,
        },
        {
          filterPropertyName: 'key',
          filterFn: (values:string[]) => {
            pagedFilter.appendQuery({
              key: values ? values[0] : undefined,
            });
          },
        },
      ),
      columnHelper.accessor(
        'valueFrom',
        {
          header: 'From',
          enableSorting: false,
        },
        {
          filterPropertyName: 'valueFrom',
          filterFn: (values:string[]) => {
            pagedFilter.appendQuery({
              valueFrom: values ? values[0] : undefined,
            });
          },
        },
      ),
      columnHelper.accessor(
        'valueTo',
        {
          header: 'To',
          enableSorting: false,
        },
        {
          filterPropertyName: 'valueTo',
          filterFn: (values:string[]) => {
            pagedFilter.appendQuery({
              valueTo: values ? values[0] : undefined,
            });
          },
        },
      ),
      columnHelper.accessor(
        'upn',
        {
          header: 'Account',
          enableSorting: false,
        },
        {
          filterPropertyName: 'account',
          filterFn: (values:string[]) => {
            pagedFilter.appendQuery({
              account: values ? values[0] : undefined,
            });
          },
        },
      ),
    ]
  ), [columnHelper, pagedFilter]);

  const entityTypeAsText = useEntityTypeAsText();

  const { store } = useTableStoreV8(
    `entityChangelog_${entityType}_${entityId}`,
    {
      visibilityState: columnsToVisibilityState(columns),
    },
  );

  const state = useStore(store);

  return (
    <Modal
      show={show}
      size="xl"
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Changelog for
          {' '}
          {entityTypeAsText(entityType)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { !pagedChanges
          ? <Spinner animation="border" />
          : (
            <TableFromPageable
              id="changelog"
              state={state}
              columnDefs={columns as PageableColumnDefV8<IChangelog, unknown>[]}
              sorting={pagedFilter.sorting}
              setSorting={pagedFilter.setSorting}
              pagedResult={pagedChanges}
              setPage={pagedFilter.setPage}
              pageSize={pagedFilter.pageableQuery.pageSize}
              filterValues={pagedFilter.pageableQuery}
              isFiltered={pagedFilter.isFiltered}
              resetFilters={pagedFilter.reset}
            />
          )}
      </Modal.Body>
    </Modal>
  );
};

interface IButtonProps {
  entityId:string|number,
  entityType:EntityType,
  filterByObjectTypes?: string[]|string|undefined,
}

export const EntityChangelogButton = (props:IButtonProps) => {
  const { entityId, entityType, filterByObjectTypes } = props;
  const [showChangelog, setShowChangelog] = useState(false);

  const openChangelog = () => {
    setShowChangelog(true);
  };

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={(
          <Tooltip>
            Show changelog
          </Tooltip>
        )}
      >
        <Button variant="link" onClick={openChangelog} className="px-0">
          <Icon name="clock" size="18" />
        </Button>
      </OverlayTrigger>
      <EntityChangelogModal
        show={showChangelog}
        handleClose={() => setShowChangelog(false)}
        entityId={entityId}
        entityType={entityType}
        filterByObjectTypes={filterByObjectTypes}
      />
    </>
  );
};
