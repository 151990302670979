import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@ailibs/feather-react-ts';
import { Button } from 'react-bootstrap';
import { useStore } from 'zustand';
import {
  createPageableColumnHelper, IPagedTableFilter,
} from '../../common/table/PagedResultFilter';
import { PagedResult } from '../../types/PagedResult';
import { ISecurityAdvisoryListOptions, ISecurityAdvisoryWithVulnerabilityCount } from '../../types/Types';
import { PageableColumnDefV8 } from '../../common/table';
import { getSecurityAdvisoryUrl } from '../vulnerabilities/SoftwareTable';
import { ClipboardCopy } from '../../components/ClipboardCopy';
import { searchQueryKeys } from '../vulnerabilities/VulnerabilityFilter';
import ROUTES from '../../routing/Routes';
import { Module } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountProvider';
import { columnsToVisibilityState, useTableStoreV8 } from '../../common/table/TableStoreV8';
import { TableFromPageable } from '../../common/table/TableFromPageable';

export const SecurityAdvisoryTable = ({
  data,
  pagedTableFilter,
  disableFilters,
  disablePagination,
  disableColumnSelect,
  disableSorting,
}:{
  data: PagedResult<ISecurityAdvisoryWithVulnerabilityCount>,
  pagedTableFilter?: IPagedTableFilter<ISecurityAdvisoryListOptions>,
  disableFilters?: boolean,
  disablePagination?: boolean,
  disableColumnSelect?: boolean,
  disableSorting?: boolean,
}) => {
  const navigate = useNavigate();
  const { hasModuleRole } = useAccount();

  const columnHelper = createPageableColumnHelper<ISecurityAdvisoryWithVulnerabilityCount>();

  const assetColumns = useMemo(() => {
    const columns = [
      columnHelper.accessor(
        'id',
        {
          header: 'Id',
          cell: ({ getValue }) => {
            const value = getValue();

            const externalUri = getSecurityAdvisoryUrl(value);

            return externalUri
              ? (
                <Link target="_blank" to={externalUri}>
                  {value}
                  <Icon name="external-link" size="16" className="ms-1" />
                </Link>
              ) : <ClipboardCopy>{value}</ClipboardCopy>;
          },
          enableColumnFilter: !disableFilters,
          enableSorting: disableSorting !== true,
        },
        {
          filterPropertyName: 'id',
          sortPropertyName: 'id',
          filterFn: pagedTableFilter
            ? (values:string[]) => {
              pagedTableFilter.appendQuery({
                id: values ? values[0] : undefined,
              });
            }
            : undefined,
        },
      ),
      columnHelper.accessor(
        'name',
        {
          header: 'Name',
          enableColumnFilter: !disableFilters,
          enableSorting: disableSorting !== true,
        },
        {
          filterPropertyName: 'name',
          sortPropertyName: 'name',
          filterFn: pagedTableFilter
            ? (values:string[]) => {
              pagedTableFilter.appendQuery({
                name: values ? values[0] : undefined,
              });
            }
            : undefined,
        },
      ),
      columnHelper.accessor(
        'assetCount',
        {
          cell: ({ getValue, row }) => {
            const searchParams = new URLSearchParams();
            searchParams.set(searchQueryKeys.securityAdvisory, row.original.id);

            return (
              <Button
                variant="link"
                onClick={() => {
                  navigate({
                    pathname: ROUTES.assets.uri,
                    search: searchParams.toString(),
                  });
                }}
              >
                {getValue()}
                <Icon name="arrow-right-circle" className="ms-1" size="18" />
              </Button>
            );
          },
          header: 'Asset count',
          enableColumnFilter: false,
          enableSorting: disableSorting !== true,
        },
        {
          filterPropertyName: 'assetCount',
          sortPropertyName: 'assetCount',
        },
      ),
    ];

    if (hasModuleRole(Module.vulnerability, 'read')) {
      columns.push({
        ...columnHelper.accessor('vulnerabilityCount', {
          header: 'Vulnerability count',
          cell: ({ getValue, row }) => {
            const searchParams = new URLSearchParams();
            searchParams.set(searchQueryKeys.securityAdvisory, row.original.id);

            return (
              <Button
                variant="link"
                onClick={() => {
                  navigate({
                    pathname: ROUTES.vulnerabilities.uri,
                    search: searchParams.toString(),
                  });
                }}
              >
                {getValue()}
                <Icon name="arrow-right-circle" className="ms-1" size="18" />
              </Button>
            );
          },
          enableColumnFilter: false,
          enableSorting: disableSorting !== true,
        }),
        customFilter: {
          filterPropertyName: 'vulnerabilityCount',
          sortPropertyName: 'vulnerabilityCount',
        },
      });
    }

    return columns;
  }, [columnHelper, disableFilters, pagedTableFilter, disableSorting, hasModuleRole, navigate]);

  const { store: tableStore } = useTableStoreV8(
    'asset-security-advisories',
    {
      visibilityState: columnsToVisibilityState(assetColumns),
    },
  );

  const tableState = useStore(tableStore);

  return (
    <TableFromPageable
      id="assets-security-advisories-table"
      state={tableState}
      columnDefs={assetColumns as PageableColumnDefV8<ISecurityAdvisoryWithVulnerabilityCount, unknown>[]}
      pagedResult={data}
      setPage={(page) => {
        if (pagedTableFilter?.setPage) pagedTableFilter.setPage(page);
      }}
      pageSize={pagedTableFilter?.pageableQuery.pageSize ?? -1}
      resetFilters={() => { if (pagedTableFilter?.reset) pagedTableFilter.reset(); }}
      filterValues={pagedTableFilter?.pageableQuery ?? {}}
      isFiltered={pagedTableFilter?.isFiltered ?? false}
      sorting={pagedTableFilter?.sorting}
      setSorting={pagedTableFilter?.setSorting}
      disablePagination={disablePagination}
      disableColumnSelect={disableColumnSelect}
      disableFilters={disableFilters}
    />
  );
};
