/* eslint-disable indent */
import React, { useCallback, useState } from 'react';
import { Table } from '@tanstack/react-table';
import ReactTableV8, { BaseReactTableV8Props, TableColumnDefV8 } from './ReactTableV8';
import PaginationV8, { IPaginationMutableState } from './PaginationV8';

export type TableFromArrayProps<T, > = BaseReactTableV8Props<T, TableColumnDefV8<T, unknown>> & {
  data: T[];
};

export const TableFromArray = <T, >({
  data,
  columnDefs,
  disableFilters,
  disableColumnSelect,
  onRowClick,
  rowProps,
  rowPropSelector,
  rowClassNameResolver,
  CustomToolbarElements,
  clickRowToToggleExpand,
  size,
  disablePagination,
  paginationMinData,
  refresh,
  hover,
  state,
  emptyContent,
  className,
  alternatingRowColors,
  renderMarkdown,
  noMargin,
}: TableFromArrayProps<T> & {
  disablePagination?: boolean,
  paginationMinData?: number,
}) => {
  const [table, setTable] = useState<Table<T>>();
  const [renderIndex, setRenderIndex] = useState(0);

  const onTableInitialized = useCallback((initializedTable:Table<T>) => {
    setTable(initializedTable);
  }, []);

  // This is somewhat of a hack until I can properly refactor the table.
  // Since paging for in-memory array tables are handled by react table, and
  // for our server side paged table by us outside the table, we need to
  // trigger a paging re-render when table paging has changed. Using this
  // counter to trigger a state update in this component to re-draw.
  const onTablePaginationChanged = () => {
    setRenderIndex(renderIndex + 1);
  };

  const onPaginationChange = (newPagination:IPaginationMutableState) => {
    if (table) {
      const newPage = newPagination.page - 1;
      table.setPageIndex(newPage);
      table.setPageSize(newPagination.pageSize);
    }
  };

  const { pagination } = table?.getState() ?? {};

  const hidePagination = disablePagination
  || (paginationMinData !== undefined
    && (table?.getFilteredRowModel().rows.length ?? 0) < paginationMinData
  );

  return (
    <>
      <ReactTableV8
        data={data}
        onTableInitialized={onTableInitialized}
        columnDefs={columnDefs}
        disableFilters={disableFilters}
        disableColumnSelect={disableColumnSelect}
        onRowClick={onRowClick}
        rowProps={rowProps}
        rowPropSelector={rowPropSelector}
        rowClassNameResolver={rowClassNameResolver}
        CustomToolbarElements={CustomToolbarElements}
        clickRowToToggleExpand={clickRowToToggleExpand}
        size={size}
        refresh={refresh}
        hover={hover}
        state={state}
        emptyContent={emptyContent}
        className={className}
        alternatingRowColors={alternatingRowColors}
        renderMarkdown={renderMarkdown}
        noMargin={noMargin}
        onReactTablePaginationChanged={onTablePaginationChanged}
        setCustomSorting={(s) => {
          state.setSorting(s);
        }}
      />
      {pagination && !hidePagination ? (
        <PaginationV8
          page={(pagination?.pageIndex ?? 0) + 1}
          pageSize={pagination?.pageSize}
          totalPages={table?.getPageCount()}
          totalItemCount={data.length}
          onPaginationChange={onPaginationChange}
        />
    ) : null }
    </>
);
};
