import React from 'react';
import { getDateDiffMillis } from '../utils/DateUtils';
import { getStringDate } from '../utils/StringUtils';
import { IHaveTimestamps } from '../types/Types';

export const Timestamps = ({
  created,
  updated,
}: {
  created:Date|string,
  updated?:Date|string|undefined
}) => {
  // Do not show timestamps if entity created date is not yet set
  // (entity created before field was added)
  if (new Date(created).getFullYear() < 2000) {
    return null;
  }

  return (
    <>
      <span>
        Created:
        {' '}
        {getStringDate(created)}
      </span>
      { /* require 1 minute diff before showing updated time */ }
      { updated && getDateDiffMillis(created, updated) > 60000
        ? (
          <span>
            , last updated:
            {' '}
            {getStringDate(updated)}
          </span>
        )
        : null}
    </>
  );
};

export const TimestampsForEntity = (props: { entity:IHaveTimestamps }) => {
  const { entity } = props;
  return <Timestamps created={entity.created} updated={entity.updated} />;
};
