import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useStore } from 'zustand';
import { ISupportProperties, IProperty } from '../types/Types';
import { TableColumnDefV8 } from '../common/table';
import { columnsToVisibilityState, useTableStoreV8 } from '../common/table/TableStoreV8';
import { TableFromArray } from '../common/table/TableFromArray';
import { Direction } from '../common/table/PagedResultFilter';

export const PropertiesTable = ({ assetId, properties }:ISupportProperties&{assetId:string}) => {
  const columnHelper = createColumnHelper<IProperty>();

  const tableColumnDefs = useMemo<TableColumnDefV8<IProperty, unknown>[]>(() => {
    const c = [];

    c.push({
      ...columnHelper.accessor('key', {
        header: 'Key',
      }),
    });

    c.push({
      ...columnHelper.accessor('value', {
        header: 'Value',
      }),
    });

    return c as TableColumnDefV8<IProperty, unknown>[];
  }, [columnHelper]);

  const assetPropertiesTableStoreId = `asset_properties_${assetId}`;

  const { store: tableStore } = useTableStoreV8(
    assetPropertiesTableStoreId,
    {
      visibilityState: columnsToVisibilityState(tableColumnDefs),
      initialSorting: [
        { property: 'key', direction: Direction.asc },
      ],
    },
  );

  const tableState = useStore(tableStore);

  if (!properties) return null;

  return (
    <TableFromArray
      className="asset-properties-table"
      state={tableState}
      columnDefs={tableColumnDefs}
      data={properties}
      paginationMinData={10}
      hover={false}
      alternatingRowColors
      disableFilters
      disableColumnSelect
    />
  );
};
