import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { Route, Routes } from '../routing/Routes';
import { copyClipboard } from '../utils/Utils';

export const CopyRouteLinkButton = ({
  route,
  id,
  name,
}:{
  route:Route,
  id:string|number,
  name:string
}) => (
  <OverlayTrigger
    placement="top"
    overlay={(
      <Tooltip>
        Copy link to
        {' '}
        {name}
      </Tooltip>
    )}
  >
    <Button
      variant="link"
      className="ms-2 px-0"
      onClick={() => {
        copyClipboard(
          Routes.getRouteRelativeUri(route, null, null, id).toString(),
          'Copied link to clipboard',
        );
      }}
    >
      <Icon name="link" size="18" />
    </Button>
  </OverlayTrigger>
);
