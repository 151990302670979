import React from 'react';
import {
  Alert, Col, Row, Spinner,
} from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Icon } from '@ailibs/feather-react-ts';
import { useApi } from '../../query/GenericQuery';
import ItemsSelector from '../../common/ItemsSelector';
import { Module } from '../../types/AccessTypes';
import { useGetEnvironment } from '../../common/useEnvironment';
import { useAccount } from '../../providers/AccountProvider';

interface IEmailMailAuthenticitySettings {
  filterKey:string,
  domains:string[]
}

export const MailAuthenticityTab = () => {
  const { data: settings, invalidate } = useApi<IEmailMailAuthenticitySettings>('module/mailAuthenticity');
  const { hasModuleRole } = useAccount();
  const environment = useGetEnvironment();

  const save = async (domains:string[]) => {
    try {
      await axios.put('api/v1/module/mailAuthenticity/domains', {
        domains,
      });
      toast.success('Your DMARC email domains were updated. Please make sure all domains\' DNS entries are updated accordingly.', {
        toastId: 'dmarc-domain-update-success',
        updateId: 'dmarc-domain-update-success',
      });
      invalidate();
    } catch (err) {
    // noop
    }
  };

  const environmentKey = !environment || environment === 'prod'
    ? ''
    : `_${environment}`;

  return (
    <Row>
      <Col md={6}>
        <p>
          The vulnerability module for mail authenticity aids in identifying weaknesses in your domains email
          configuration, mitigating risks, and enhancing email security.
        </p>
        <div className="mb-3">
          { settings?.domains
            ? (
              <>
                <p>
                  { hasModuleRole(Module.mailAuthenticity, 'readWrite')
                    ? 'Please select the domains that should be monitored by the module:'
                    : 'Monitored domains:' }
                </p>
                <ItemsSelector
                  id="module-mail-authenticity-domains"
                  items={settings.domains}
                  inputType="text"
                  removeEnabled={hasModuleRole(Module.mailAuthenticity, 'readWrite')}
                  addEnabled={hasModuleRole(Module.mailAuthenticity, 'readWrite')}
                  addLabel="Add domain"
                  addPlaceholder="Enter an email domain..."
                  onChange={async (items) => save(items)}
                />
              </>
            )
            : <Spinner animation="border" /> }
        </div>
      </Col>
      <Col md={12}>
        <Alert variant="info" className="p-3 overflow-auto">
          <div className="me-3">
            <Icon name="info" size="32" />
          </div>
          <div>
            For the module to get access to DMARC reports, you will need to add the following DNS TXT record for
            each of the selected email domains:

            <div className="mt-3">
              <code className="pt-3" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                v=DMARC1; p=quarantine; adkim=s; aspf=s; rua=mailto:dmarc
                <strong>
                  {environmentKey}
                  +
                  {settings?.filterKey}
                </strong>
                @ivolv.cloud
              </code>
            </div>
          </div>
        </Alert>
      </Col>
    </Row>
  );
};

export default MailAuthenticityTab;
