import React, {
  RefObject,
  useCallback, useMemo,
} from 'react';
import {
  Badge, Button, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@ailibs/feather-react-ts';
import { VisibilityState, createColumnHelper } from '@tanstack/react-table';
import DOMPurify from 'dompurify';
import { IRisk, RiskStatus } from '../../types/RiskTypes';
import { TableColumnDefV8, TableCellDateFormattedV8 } from '../../common/table';
import {
  riskAsColorCssClassNames, isRiskManaged,
} from './Utils';
import { useGetSeverityAsText, useGetSignificanceAsText, useRiskStatusAsText } from '../../utils/TranslationUtils';
import {
  compareSeverity, asSeverity, severityAsCssClassName, compareSignificance,
} from '../vulnerabilities/Utils';
import { TableStateV8 } from '../../common/table/TableStoreV8';
import { Module } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountProvider';
import { Severity } from '../vulnerabilities/Types';
import { TableFromArray } from '../../common/table/TableFromArray';
import { arrIncludesSomeWithEmptyFixFn } from '../../common/table/filters/TableFilter';
import { rangeSelectFilterV8 } from '../../common/table/filters/RangeSelectFilterV8';
import { dateStringFilterFn } from '../../common/table/filters/DateStringFilterV8';
import { ClipboardCopy } from '../../components/ClipboardCopy';

interface RiskTableProps {
  openForm: (risk: IRisk) => void,
  risks: IRisk[],
  state: TableStateV8,
  defaultColumnVisibility: VisibilityState
}

interface IRiskRow extends IRisk {
  severity?: Severity
}

/**
 * React table for showing risks. Note that it uses the generic ReactTable component.
 * @see ReactTable
 */
export const RiskTable = (props: RiskTableProps) => {
  const {
    openForm, risks, state, defaultColumnVisibility,
  } = props;

  const { hasModuleRole } = useAccount();
  const navigate = useNavigate();

  const riskStatusAsText = useRiskStatusAsText();
  const severityAsText = useGetSeverityAsText();
  const significanceAsText = useGetSignificanceAsText();

  const refs = useMemo(
    () => (
      Object.values(risks).reduce(
        (acc, v) => {
          // eslint-disable-next-line no-param-reassign
          acc[v.riskId] = React.createRef<HTMLHeadingElement>();
          return acc;
        },
        {} as Record<string, RefObject<HTMLHeadingElement>>,
      )),
    [risks],
  );

  const columnHelper = createColumnHelper<IRiskRow>();

  // Important: due to the way we initialize risk table columns, we need to transfer the
  // default visibility state to the defaultHidden property of our risk columns.
  const columns = useMemo(() => {
    const mColumns = [
      {
        ...columnHelper.accessor('riskId', {
          header: 'Risk ID',
          cell: ({ row, getValue }) => (
            <OverlayTrigger
              overlay={(
                <Tooltip>
                  <div className="status">
                    { row.original.status === RiskStatus.Closed
                      ? 'Risk is closed'
                      : 'Risk is open'}
                  </div>
                  { row.original.severity && row.original.status === RiskStatus.Open
                    ? (
                      <div>
                        Severity:
                        {' '}
                        <Badge
                          style={{ fontSize: '0.7rem' }}
                          bg="none"
                          pill
                          className={`text-shadow ${severityAsCssClassName(row.original.severity)}`}
                        >
                          {severityAsText(
                            asSeverity(row.original),
                          )}
                        </Badge>
                      </div>
                    ) : null }
                </Tooltip>
              )}
            >
              <Link to={`/risk/${row.original.id}`}>
                <div id={row.original.riskId.toString()} ref={refs[row.original.riskId]} className="id-badge">
                  <Badge bg="none" className={riskAsColorCssClassNames(row.original)}>
                    {getValue()}
                  </Badge>
                </div>
              </Link>
            </OverlayTrigger>
          ),
          filterFn: 'weakEquals',
          enableHiding: false,
        }),
      },
      {
        ...columnHelper.accessor('status', {
          header: 'Status',
          cell: ({ getValue }) => riskStatusAsText(getValue() as RiskStatus),
          filterFn: arrIncludesSomeWithEmptyFixFn,
        }),
        formatter: (value:RiskStatus) => riskStatusAsText(value),
        defaultHidden: defaultColumnVisibility.status === false,
      },
      {
        ...columnHelper.accessor('severity', {
          header: 'Severity',
          cell: ({ row, getValue }) => {
            const value = getValue();

            return value && row.original.status === RiskStatus.Open
              ? (
                <Badge bg="none" className={severityAsCssClassName(value)}>
                  {severityAsText(value)}
                </Badge>
              )
              : null;
          },
          filterFn: rangeSelectFilterV8,
          sortingFn: (rowA, rowB, columnId) => (
            compareSeverity(rowA.getValue(columnId), rowB.getValue(columnId))
          ),
        }),
        filterFn: arrIncludesSomeWithEmptyFixFn,
        formatter: (value:Severity) => severityAsText(value),
        defaultHidden: defaultColumnVisibility.severity === false,
        optionsSortFn: compareSeverity,
      },
      {
        ...columnHelper.accessor('name', {
          header: 'Name',
          cell: ({ getValue }) => DOMPurify.sanitize(getValue(), { ALLOWED_TAGS: [] }),
        }),
        defaultHidden: defaultColumnVisibility.name === false,
      },
      {
        ...columnHelper.accessor('impact', {
          header: 'Impact',
          filterFn: arrIncludesSomeWithEmptyFixFn,
          cell: ({ getValue }) => (
            significanceAsText(getValue())
          ),
          sortingFn: (rowA, rowB, columnId) => (
            compareSignificance(rowA.getValue(columnId), rowB.getValue(columnId))
          ),
        }),
        defaultHidden: defaultColumnVisibility.impact === false,
        formatter: significanceAsText,
        optionsSortFn: compareSignificance,
      },
      {
        ...columnHelper.accessor('probability', {
          header: 'Probability',
          filterFn: arrIncludesSomeWithEmptyFixFn,
          cell: ({ getValue }) => (
            significanceAsText(getValue())
          ),
          sortingFn: (rowA, rowB, columnId) => (
            compareSignificance(rowA.getValue(columnId), rowB.getValue(columnId))
          ),
        }),
        defaultHidden: defaultColumnVisibility.probability === false,
        formatter: significanceAsText,
        optionsSortFn: compareSignificance,
      },
      {
        ...columnHelper.accessor('owner', {
          header: 'Owner',
          cell: ({ getValue }) => {
            const value = getValue();
            return value
              ? (
                <ClipboardCopy>
                  {value}
                </ClipboardCopy>
              ) : null;
          },
        }),
        defaultHidden: defaultColumnVisibility.owner === false,
      },
      {
        ...columnHelper.accessor('response', {
          id: 'response',
          header: 'Response',
          filterFn: arrIncludesSomeWithEmptyFixFn,
        }),
        defaultHidden: defaultColumnVisibility.response === false,
      },
      {
        ...columnHelper.accessor('created', {
          header: 'Created',
          cell: ({ getValue }) => TableCellDateFormattedV8(getValue()),
          filterFn: dateStringFilterFn,
        }),
        defaultHidden: defaultColumnVisibility.created === false,
      },
      {
        ...columnHelper.accessor('updated', {
          header: 'Updated',
          cell: ({ getValue }) => TableCellDateFormattedV8(getValue()),
          filterFn: dateStringFilterFn,
        }),
        defaultHidden: defaultColumnVisibility.updated === false,
      },
    ] as TableColumnDefV8<IRiskRow, unknown>[];

    if (hasModuleRole(Module.riskUnmanaged, 'readWrite')) {
      mColumns.push(
        columnHelper.display({
          id: 'edit',
          cell: ({ row }) => (isRiskManaged(row.original)
            ? null
            : (
              <OverlayTrigger
                overlay={<Tooltip>Edit</Tooltip>}
              >
                <Button
                  variant="link"
                  className="p-0"
                  onClick={(e) => {
                    e.stopPropagation();
                    openForm(row.original);
                  }}
                >
                  <Icon name="edit-2" size="18px" />
                </Button>
              </OverlayTrigger>
            )
          ),
          enableHiding: false,
        }),
      );
    }

    return mColumns;
  }, [
    columnHelper,
    defaultColumnVisibility.created,
    defaultColumnVisibility.impact,
    defaultColumnVisibility.name,
    defaultColumnVisibility.owner,
    defaultColumnVisibility.probability,
    defaultColumnVisibility.response,
    defaultColumnVisibility.severity,
    defaultColumnVisibility.status,
    defaultColumnVisibility.updated,
    hasModuleRole,
    openForm,
    refs,
    riskStatusAsText,
    severityAsText,
    significanceAsText,
  ]);

  const onRowClick = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>, object: IRiskRow) => {
    if (object.id) {
      if (e.button === 3 || e.ctrlKey) {
        window.open(`/risk/${object?.riskId}`, '_blank');
      } else {
        navigate(`/risk/${object?.riskId}`);
      }
    }
  }, [navigate]);

  const riskRows = useMemo(() => risks.map((r) => (
    {
      ...r,
      severity: r.status === RiskStatus.Open
        ? asSeverity(r)
        : undefined,
    })), [risks]);

  return (
    <TableFromArray
      data={riskRows}
      state={state}
      columnDefs={columns}
      onRowClick={onRowClick}
    />
  );
};

export default RiskTable;
