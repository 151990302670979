/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert, Button, Col, Form, Row, Spinner, Stack,
} from 'react-bootstrap';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Icon } from '@ailibs/feather-react-ts';
import { AdminCustomer, ICustomerConsent } from '../../types/AdminTypes';
import {
  arrayOfStringToNewLineString, filterEmails,
} from '../../utils/StringUtils';
import { useApi, useInvalidateQueries } from '../../query/GenericQuery';
import { Module } from '../../types/AccessTypes';
import ItemsSelector from '../../common/ItemsSelector';
import { useAccount } from '../../providers/AccountProvider';
import { asClientError } from '../../routing/ClientError';
import { ActiveStatusBadge } from '../../components/ActiveStatusBadge';
import { ClipboardCopy } from '../../components/ClipboardCopy';

const createFormStateForCustomer = (customer:AdminCustomer) => ({
  ...customer,
  tenantIdsAsString: customer?.tenantId,
  notificationEmailsAsString: arrayOfStringToNewLineString(customer?.notificationEmails),
});

const isInvalidTenant = (tenantId:string|undefined) => (
  !!tenantId && !/^[0-9a-f]{8}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{12}$/i.test(tenantId)
);

/**
 * The tab for customers => select customer and render the rest/modal (only for global CRSM admins)
 */
export const AdminCustomerDetailsTab = (props:{customer:AdminCustomer}) => {
  const { customer } = props;

  const [initialFormState, setInitialFormState] = useState(createFormStateForCustomer(customer));
  const [formState, setFormState] = useState(initialFormState);
  const [busy, setBusy] = useState(false);

  const { hasModuleRole, customer: accountCustomer, primaryCustomer } = useAccount();

  const invalidateCustomers = useInvalidateQueries(`module/admin/customers/${customer.id}`);

  useEffect(() => {
    const updatedFormState = createFormStateForCustomer(customer);
    setInitialFormState(updatedFormState);
    setFormState(updatedFormState);
  }, [customer]);

  const addEditCustomer = useMutation({
    mutationFn: async () => {
      try {
        const { data: updatedCustomer } = await axios<AdminCustomer>(`/api/v1/module/admin/customers${customer ? `/${customer.id}` : ''}`, {
          data: formState,
          method: customer ? 'PUT' : 'POST',
        });

        const updatedFormState = createFormStateForCustomer(updatedCustomer);
        setInitialFormState(updatedFormState);
        setFormState(updatedFormState);

        await invalidateCustomers();
        toast.success(`Customer was ${customer ? 'updated' : 'added'}`, {
          toastId: 'admin-customer-save',
          updateId: 'admin-customer-save',
        });
      } catch (err) {
        const clientError = asClientError(err);
        if (clientError) {
          toast.error(clientError.details, {
            toastId: 'admin-customer-save',
            updateId: 'admin-customer-save',
          });
        } else if (err instanceof AxiosError) {
          toast.error(err.message, {
            toastId: 'admin-customer-save',
            updateId: 'admin-customer-save',
          });
        }
      }
    },
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setBusy(true);
    e.preventDefault();
    try {
      await addEditCustomer.mutateAsync();
    } finally {
      setBusy(false);
    }
  };

  const resetForm = () => {
    setFormState(createFormStateForCustomer(customer));
  };

  const hasWriteAccess = hasModuleRole(Module.admin, 'readWrite');

  // A customer can have many tenants, but only one enabled to m365
  // If one tenant is enabled, we assume the customer is enabled.
  // This inconsitency in mapping can potentially be challenging, but its
  // the way it is for now.
  const { data: consent, isFetched: isConsentFetched } = useApi<ICustomerConsent>(
    `module/admin/customers/${encodeURIComponent(customer.id)}/consent/${Module.m365}`,
  );

  const isDirty = useMemo(() => (
    JSON.stringify(formState) !== JSON.stringify(initialFormState)
  ), [formState, initialFormState]);

  return (
    <Form onSubmit={onSubmit}>
      <Row>
        <Col md={8} className="mb-3">
          <Form.Label>Name:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Name"
            autoComplete="off"
            disabled={!hasWriteAccess}
            value={formState.name}
            onChange={(e) => setFormState((state) => ({ ...state, name: e.target.value }))}
          />
          <code className="small">
            <ClipboardCopy>{formState.id}</ClipboardCopy>
          </code>
        </Col>
        <Col md={4} className="mb-3">
          <Form.Check
            label={(
              <span>
                Enabled?
                {customer.status !== 'active' && customer.status !== 'disabled'
                  ? <ActiveStatusBadge status={customer.status} className="ms-2" />
                  : null }
              </span>
            )}
            id="enabled"
            checked={formState.active}
            disabled={accountCustomer.id === customer.id}
            onChange={(e) => {
              setFormState((state) => ({ ...state, active: e.target.checked }));
            }}
          />
          { !customer.active
            ? (
              <Alert variant={formState.active !== initialFormState.active ? 'warning' : 'danger'} className="p-2 mt-2">
                <div>
                  { formState.active !== initialFormState.active
                    ? <span>Save to activate the customer.</span>
                    : <span>This customer is inactive, preventing its users from accessing the platform.</span>}
                </div>
              </Alert>
            )
            : null }
        </Col>
        <Col md={12} className="mb-3">
          <Form.Label>Description:</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Description"
            value={formState.description}
            disabled={!hasWriteAccess}
            onChange={(e) => setFormState((state) => ({ ...state, description: e.target.value }))}
          />
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label>Tenant ID:</Form.Label>
          <Form.Control
            placeholder="Tenant IDs (one line per each)"
            value={formState?.tenantId}
            disabled={!hasWriteAccess || primaryCustomer?.id === customer?.id}
            // eslint-disable-next-line max-len
            isInvalid={isInvalidTenant(formState.tenantId)}
            onChange={(e) => setFormState((state) => ({ ...state, tenantId: e.target.value }))}
          />
          { primaryCustomer?.id === customer?.id
            ? (
              <Alert variant="info" className="mt-2 p-2">
                Your user does not have the ability to change the tenantId, as it is this tenant that
                grants you access to the platform.
              </Alert>
            )
            : null }
          { isInvalidTenant(formState.tenantId) || isInvalidTenant(customer.tenantId)
            ? (
              <Alert variant="danger" className="mt-2 p-2">
                <div>
                  The tenantId is invalid.
                  { isInvalidTenant(customer.tenantId)
                    ? (
                      <div className="mt-3">
                        Starting from version 1.2, customers are no longer allowed to have multiple tenants.
                        In this case, the customer has a legacy configuration with multiple tenants linked to it.
                        Please update the customer to reference its primary tenant.
                      </div>
                    )
                    : null }
                </div>
              </Alert>
            ) : null }
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label>Notification emails:</Form.Label>

          <ItemsSelector
            id="customer-settings-notification-emails"
            items={formState.notificationEmails}
            inputType="email"
            removeEnabled={hasWriteAccess}
            addEnabled={hasWriteAccess}
            addLabel="Add notification email"
            addPlaceholder="Enter notification email..."
            onChange={(emails) => {
              setFormState((state) => ({
                ...state,
                notificationEmails: emails.filter(filterEmails),
              }));
            }}
          />
        </Col>
        <Col md={12} className="mb-3">
          { isConsentFetched
            ? consent
              ? (
                <span>
                  <Icon name="check-square" />
                  {' '}
                  Microsoft 365 is enabled for:
                  <ul>
                    <li key={consent.targetId}>
                      <code className="small">
                        <ClipboardCopy>{ consent.targetId }</ClipboardCopy>
                      </code>
                    </li>
                  </ul>
                </span>
              )
              : (
                <span>
                  <Icon name="square" />
                  {' '}
                  Microsoft 365 Analytics is not enabled
                </span>
              )
            : <Spinner animation="border" /> }
        </Col>
        { hasWriteAccess ? (
          <Col md={12} className="mb-3">
            <Stack direction="horizontal" gap={2}>
              <Button
                disabled={!isDirty || busy}
                type="submit"
              >
                Save
              </Button>
              {
                /*
              <Button
                variant="danger"
                disabled={isDirty || busy}
                onClick={async () => {
                  // eslint-disable-next-line no-restricted-globals, no-alert
                  if (confirm(
                    `Are you sure you would like to delete the customer ${customer.name}?\n\nThe customer will be soft
deleted, and will be available in the database for a period before being permanently deleted.`,
                  )) {
                    await deleteCustomerMutation.mutateAsync(customer.id);
                  }
                }}
              >
                Delete
              </Button>
              */
              }
              <Button
                variant="secondary"
                disabled={!isDirty || busy}
                type="reset"
                onClick={resetForm}
              >
                Cancel
              </Button>
            </Stack>
          </Col>
        ) : null }
      </Row>
    </Form>
  );
};

export default AdminCustomerDetailsTab;
