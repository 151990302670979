import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Col, Form, Row, Card, Button,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { QueryClient } from '@tanstack/react-query';
import { getOrFetchFromApi, useApi, useApiLoaderData } from '../../query/GenericQuery';
import { IAsset } from '../../types/AssetsTypes';
import { useAssetTypeAsText } from '../../utils/TranslationUtils';
import ROUTES from '../../routing/Routes';
import { CardCloseBackButton } from '../../components/CardCloseButton';
import { EntityType } from '../../types/EntityTypes';
import { IAccountDetails } from '../../providers/AccountProvider';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { assetTypesAsString, IVulnerabilityListOptions } from '../../types/Types';
import { PagedResult } from '../../types/PagedResult';
import { IVulnerability, VulnerabilityStatus } from '../vulnerabilities/Types';
import { VulnerabilitiesTable } from '../vulnerabilities/VulnerabilitiesTable';
import { PropertiesTable } from '../../components/PropertiesTable';
import { EntityCardFooter } from '../../components/EntityCardFooter';
import { ValidPageSizes } from '../../common/table/PaginationV8';
import { Direction, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { vulnerabilitiesTableId } from '../vulnerabilities/VulnerabilitiesPage';

interface IData {
  asset: IAsset,
}

/**
 * React component (using hooks) for showing details about a given asset.
 */
export const AssetsDetailPage:IComponentWithLoader<IData, {id?:string}> = {
  loader: async (
    queryClient:QueryClient,
    account:IAccountDetails,
    pageSize:ValidPageSizes,
    args?:{id?:string}|undefined,
  ) => {
    const asset = await getOrFetchFromApi<IAsset>(
      queryClient,
      args?.id && `assets/${encodeURIComponent(args?.id)}`,
    );

    return {
      asset,
    };
  },
  Component: () => {
    const { id } = useParams();
    const assetTypeAsText = useAssetTypeAsText();
    const navigate = useNavigate();

    // TODO: Refactor to use https://nodejs.org/api/querystring.html
    const { data: asset } = useApiLoaderData<IAsset, IData>(
      id && `assets/${encodeURIComponent(id)}`,
      (loaderData) => loaderData.asset,
    );

    const pagedTableFilter = usePagedTableFilter<IVulnerabilityListOptions>(
      'asset-vulnerabilities',
      {
        assets: [asset?.id],
      },
      {
        status: [VulnerabilityStatus.Open],
        assets: [asset?.id],
      },
      [
        { property: 'severity', direction: Direction.desc },
      ],
    );

    const { data: pagedVulnerabilities } = useApi<PagedResult<IVulnerability>>(
      'vulnerabilities',
      {
        ...pagedTableFilter.pageableQuery,
        assets: [asset?.id],
      },
    );

    return (
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <Icon name={ROUTES.assets.icon} />
              {' '}
              { asset.name }
              <CardCloseBackButton />
            </Card.Header>
            <Card.Body>
              <>
                <Form>
                  <Row>
                    <Col md={12} className="mb-3">
                      <div>
                        <div className="form-label">Name:</div>
                        <div>{asset.name}</div>
                      </div>
                    </Col>
                    { asset?.friendlyId
                      ? (
                        <Col md={8} className="mb-3">
                          <div>
                            <div className="form-label">Unique ID:</div>
                            <div><code>{asset?.friendlyId}</code></div>
                          </div>
                        </Col>
                      )
                      : null }
                    <Col md={4} className="mb-3">
                      <div>
                        <div className="form-label">Type:</div>
                        <Form.Select value={asset?.type} disabled>
                          { assetTypesAsString.map((type) => (
                            <option key={type} value={type}>{assetTypeAsText(type, false)}</option>
                          ))}
                        </Form.Select>
                      </div>
                    </Col>
                  </Row>
                  { asset.properties?.length
                    ? (
                      <Row>
                        <Col md={12}>
                          <fieldset className="mb-3 pb-3">
                            <legend>Properties:</legend>
                            <PropertiesTable
                              assetId={asset.id}
                              properties={asset.properties}
                            />
                          </fieldset>
                        </Col>
                      </Row>
                    ) : null }
                </Form>
                <EntityCardFooter
                  timestamps={asset}
                  entity={{ entityId: asset.id, entityType: EntityType.Asset }}
                  linkRoute={ROUTES.assets}
                  additionalDetails={(
                    <span>
                      {'ID: '}
                      { asset.id }
                      { asset.friendlyId ? (
                        <>
                          , friendly ID:
                          {' '}
                          {asset.friendlyId}
                        </>
                      ) : ''}
                    </span>
                  )}
                />
              </>
            </Card.Body>
          </Card>

          { pagedVulnerabilities?.items.length
            ? (
              <Card>
                <Card.Header>
                  Vulnerabilities
                  <div className="float-end">
                    <Button
                      type="button"
                      variant="icon"
                      className="m-0 p-0"
                      onClick={() => navigate(`${ROUTES.vulnerabilitySummary.uri}?asset_id=${encodeURIComponent(asset?.id)}`)}
                    >
                      <Icon name="bar-chart-2" />
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body className="overflow-auto">
                  <VulnerabilitiesTable
                    isPaged
                    id={vulnerabilitiesTableId}
                    pagedResult={pagedVulnerabilities}
                    pagedTableFilter={pagedTableFilter}
                    hide={{ asset: true }}
                  />
                </Card.Body>
              </Card>
            ) : null }
        </Col>
      </Row>
    );
  },
};

export default AssetsDetailPage;
