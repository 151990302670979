/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col, Container, Form, Row,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { useDebouncedCallback } from 'use-debounce';
import { useLocation } from 'react-router-dom';

const validPageSizes = [10, 15, 20, 30, 40, 50, 100] as const;
export type ValidPageSizes = typeof validPageSizes[number];

export interface IPaginationMutableState {
  page: number,
  pageSize: number,
}

export interface IPaginationState extends IPaginationMutableState {
  totalPages?: number,
  totalItemCount?: number,
}

export interface IPaginationProps extends IPaginationState {
  onPaginationChange: (pagination:IPaginationState) => Promise<void>|void,
  disabled?: boolean,
  isLoading?: boolean
  className?: string
}

export const asValidPageSize = (pageSize:number) : ValidPageSizes => {
  if (!validPageSizes.includes(pageSize as ValidPageSizes)) {
    if (pageSize > validPageSizes[validPageSizes.length - 1]) {
      return validPageSizes[validPageSizes.length - 1];
    }
    return validPageSizes[0];
  }
  return pageSize as ValidPageSizes;
};

export const usePageCache = (id:string) => {
  const location = useLocation();
  const key = `paging_page_${id}_${location.key}`;
  const pageSizeKey = 'table_state_pagination_';

  // Using same key and format as react table for interoperability
  let defaultPageSize = { pageIndex: 0, pageSize: 15 };

  let value:IPaginationMutableState|undefined;
  const valueAsString = sessionStorage.getItem(key);
  try {
    value = valueAsString
      ? JSON.parse(valueAsString) as IPaginationMutableState
      : undefined;
    const defaultPageSizeAsString = localStorage.getItem(pageSizeKey);
    defaultPageSize = defaultPageSizeAsString
      ? JSON.parse(defaultPageSizeAsString)
      : defaultPageSize;

    defaultPageSize.pageSize = asValidPageSize(defaultPageSize.pageSize);
  } catch (err) {
    sessionStorage.removeItem(key);
  }

  if (value) value.pageSize = asValidPageSize(value.pageSize);
  const [pagination, setPagination] = useState(value ?? { page: 1, pageSize: defaultPageSize.pageSize });

  return {
    pagination,
    setPage: (paginationState:IPaginationMutableState) => {
      setPagination(paginationState);
      sessionStorage.setItem(key, JSON.stringify(paginationState));
      localStorage.setItem(
        pageSizeKey,
        JSON.stringify({
          pageIndex: 0,
          pageSize: paginationState.pageSize,
        }),
      );
    },
  };
};

/**
 * Custom Pagination component used by ReactTable
 */
export const PaginationV8 = ({
  onPaginationChange,
  page,
  totalItemCount,
  totalPages,
  pageSize,
  disabled,
  isLoading,
  className,
}: IPaginationProps) => {
  const canPreviousPage = page > 1;
  const canNextPage = totalPages && page < totalPages;

  const [inputPage, setInputPage] = useState<number>(page ?? -1);

  useEffect(() => {
    setInputPage(page);
  }, [page]);

  const activateInputPage = () => {
    if (inputPage > 0 && inputPage <= (totalPages ?? 1)) {
      onPaginationChange({
        page: inputPage,
        pageSize,
      });
    }
  };

  const setAndNotifyCurrentPage = (newCurrentPage:number) => {
    setInputPage(newCurrentPage);
    setTimeout(() => {
      onPaginationChange({
        page: newCurrentPage,
        pageSize,
      });
    });
  };

  useEffect(() => {
    const validPageSize = asValidPageSize(pageSize);
    if (validPageSize !== pageSize) {
      onPaginationChange({
        page,
        pageSize: validPageSize,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedActivatePage = useDebouncedCallback(() => {
    activateInputPage();
  }, 1000);

  return (
    <Container fluid className={`paginator ${className}`}>
      <Row>
        <Col className="d-flex justify-content-left align-items-left ps-0 d-none d-md-block" style={{ paddingTop: 6 }}>
          { totalItemCount
            ? (
              <span>
                Total items:
                {' '}
                {totalItemCount}
              </span>
            ) : null }
        </Col>
        { (totalPages ?? 1) > 1
          ? (
            <Col className="d-flex justify-content-center align-items-center">
              <div className={`${canPreviousPage ? 'cursor-pointer' : ''}`} title="First page">
                <Button
                  variant="icon"
                  size="sm"
                  disabled={!canPreviousPage || disabled}
                  onClick={() => {
                    if (canPreviousPage) setAndNotifyCurrentPage(1);
                  }}
                >
                  <Icon
                    name="chevrons-left"
                    className="align-middle"
                    size="25px"
                    color={canPreviousPage ? '#495158' : '#dee1e6'}
                  />
                </Button>
              </div>
              <div className={`${canPreviousPage ? 'cursor-pointer' : ''}`} title="Previous page">
                <Button
                  variant="icon"
                  size="sm"
                  disabled={!canPreviousPage || disabled}
                  onClick={() => {
                    if (canPreviousPage) setAndNotifyCurrentPage(page - 1);
                  }}
                >
                  <Icon
                    name="chevron-left"
                    className="align-middle"
                    size="25px"
                    color={canPreviousPage ? '#495158' : '#dee1e6'}
                  />
                </Button>
              </div>
              <div className="d-block d-sm-none text-nowrap">
                {page}
                {' '}
                of
                {' '}
                {totalPages}
              </div>
              <div className="d-none d-sm-block">
                <Form.Control
                  value={isLoading ? '' : inputPage > 0 ? inputPage : ''}
                  type="number"
                  disabled={disabled}
                  onBlur={activateInputPage}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                      activateInputPage();
                    }
                  }}
                  onChange={(e) => {
                    const newPage = parseInt(e.target.value, 10);
                    setInputPage(Number.isNaN(newPage) ? -1 : newPage);
                    debouncedActivatePage();
                  }}
                  style={{ width: 55 }}
                  className="ps-2 pe-2"
                />
              </div>
              <div className="ps-1 text-nowrap d-none d-sm-block">
                of
                {' '}
                <span
                  className={
                    totalPages && totalPages > page + 1
                      ? 'has-more'
                      : ''
                  }
                >
                  { isLoading ? '' : totalPages && totalPages > 0 ? totalPages : 1 }
                </span>
              </div>
              <div className={`${canNextPage ? 'cursor-pointer' : ''}`} title="Next page">
                <Button
                  variant="icon"
                  size="sm"
                  disabled={!canNextPage || disabled}
                  onClick={() => {
                    if (canNextPage) setAndNotifyCurrentPage(page + 1);
                  }}
                >
                  <Icon
                    name="chevron-right"
                    className="align-middle"
                    size="25px"
                    color={canNextPage ? '#495158' : '#dee1e6'}
                  />
                </Button>
              </div>
              <div className={`${canNextPage ? 'cursor-pointer' : ''}`} title="Last page">
                <Button
                  variant="icon"
                  size="sm"
                  disabled={!canNextPage || disabled}
                  onClick={() => {
                    if (canNextPage) setAndNotifyCurrentPage(totalPages);
                  }}
                >
                  <Icon
                    name="chevrons-right"
                    className="align-middle"
                    size="25px"
                    color={canNextPage ? '#495158' : '#dee1e6'}
                  />
                </Button>
              </div>
            </Col>
          ) : null }
        <Col className="d-flex justify-content-end align-content-center pe-0">
          <span className="align-self-center me-2 d-none d-sm-block">
            Page size:
          </span>
          <Form.Select
            size="sm"
            disabled={disabled}
            value={pageSize}
            onChange={(e) => onPaginationChange({
              page,
              pageSize: Number(e.target.value) as ValidPageSizes,
            })}
            style={{ width: 75 }}
          >
            {validPageSizes
              .map((pgSize) => (
                <option key={pgSize} value={pgSize}>
                  {pgSize}
                </option>
              ))}
          </Form.Select>
        </Col>
      </Row>
    </Container>
  );
};

export default PaginationV8;
