/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { FilterFn } from '@tanstack/react-table';
import { useStore } from 'zustand';
import RenderHtml from '../../components/RenderHtml';
import {
  formatDateTimes, getStringDate, getUTCStringDateOnly,
} from '../../utils/StringUtils';
import {
  IContent, ITableContentBody,
} from './Types';
import { TableColumnDefV8 } from '../../common/table';
import { columnsToVisibilityState, useTableStoreV8 } from '../../common/table/TableStoreV8';
import { IDataTypeDetection, identifyDataType } from '../../utils/Utils';
import { TableFromArray } from '../../common/table/TableFromArray';
import { rangeSelectFilterV8 } from '../../common/table/filters/RangeSelectFilterV8';
import { dateStringFilterFn } from '../../common/table/filters/DateStringFilterV8';
import { arrIncludesSomeWithEmptyFixFn } from '../../common/table/filters/TableFilter';

const findAppropriateFilter = (
  dataType:IDataTypeDetection,
  max:number|undefined,
):FilterFn<unknown>|'includesString'|'arrIncludes' => {
  if (dataType.isBoolean) {
    return arrIncludesSomeWithEmptyFixFn;
  }
  if (dataType.isDateString) {
    return dateStringFilterFn;
  }

  if (dataType.isInt) {
    return max && max > 10 ? rangeSelectFilterV8 : arrIncludesSomeWithEmptyFixFn;
  }

  if (dataType.isFloat) {
    return rangeSelectFilterV8;
  }

  return 'includesString';
};

export const RenderContentSegment = ({
  id,
  content,
}:{
  id:string,
  content:IContent
}) => {
  const [tableColumns, tableData] = useMemo(() => {
    if (content.type === 'table') {
      const mTableContent = content.body as ITableContentBody;

      if (!mTableContent?.headers) {
        return [null, null];
      }

      const mTableData:unknown[][] = [];
      mTableContent.rows.forEach((r, rowIdx) => { mTableData[rowIdx] = []; });

      const columns:TableColumnDefV8<unknown, unknown>[] = mTableContent.headers.map((h, columnIdx) => {
        const dataType = identifyDataType(mTableContent.rows.map((row) => row[columnIdx]));
        const max = dataType.isFloat || dataType.isInt
          ? (mTableContent.rows.map((row) => row[columnIdx]) ?? [])
            .reduce((a, b) => (b ? Math.max(a, parseInt(b, 10)) : a), -Infinity)
          : undefined;

        const filterFn = findAppropriateFilter(dataType, max);

        if (dataType.isInt) {
          mTableContent.rows.forEach((r, rowIdx) => {
            const val = r[columnIdx];
            mTableData[rowIdx][columnIdx] = val
              ? parseInt(val, 10)
              : null;
          });
        } else if (dataType.isFloat) {
          mTableContent.rows.forEach((r, rowIdx) => {
            const val = r[columnIdx];
            mTableData[rowIdx][columnIdx] = val
              ? parseFloat(val)
              : null;
          });
        } else if (dataType.isDateString) {
          mTableContent.rows.forEach((r, rowIdx) => {
            const val = r[columnIdx];
            mTableData[rowIdx][columnIdx] = val
              ? dataType.dateHasTime
                ? getStringDate(val)
                : getUTCStringDateOnly(val)
              : null;
          });
        } else {
          mTableContent.rows.forEach((r, rowIdx) => {
            mTableData[rowIdx][columnIdx] = r[columnIdx];
          });
        }

        return {
          accessorKey: `${columnIdx}`,
          header: h,
          enableColumnFilter: true,
          enableGlobalFilter: true,
          filterFn,
          max,
        };
      });

      return [columns, mTableData];
    }
    return [null, null];
  }, [content]);

  const { store: tableStore } = useTableStoreV8(
    id,
    {
      visibilityState: tableColumns ? columnsToVisibilityState(tableColumns) : [],
    },
  );

  const tableState = useStore(tableStore);

  switch (content.type) {
  case 'table':
    return tableColumns && tableData
      ? (
        <TableFromArray
          className="mb-0"
          columnDefs={tableColumns}
          data={tableData}
          state={tableState}
          renderMarkdown
          noMargin
        />
      )
      : null;
  default:
    return (
      <RenderHtml allowedTags={['a', 'br']} allowedAttributes={['target']}>
        {formatDateTimes(content.body.toString())}
      </RenderHtml>
    );
  }
};
