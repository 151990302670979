import React, { useMemo } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import {
  Button, Col, OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { containsSecurityLevel } from './Utils';
import { ImplementationPercentRadarChart, createImplementationPercentRadarData } from './ImplementationPercentRadarChart';
import { ImplementationPercentDonutChart, createSecurityLevelImplementationStats } from './ImplementationPercentDonutChart';
import { ImplemenationPercentStackedChart, createImplementationPercentStackedChartData } from './ImplementationPercentStackedChart';
import { IVulnerabilityListOptions } from '../../types/Types';
import { IVulnerability, SecurityLevel } from './Types';
import { useTranslation } from '../../providers/TranslationProvider';
import VulnerabilityFilter from './VulnerabilityFilter';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { ILoaderData, vulnerabilitiesLoader } from './loader';
import { VulnerabilityControlQueryUtil } from './VulnerabilityControlFilter';
import { Module } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountProvider';
import { useApiLoaderData } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';

export const VulnerabilitySummaryPage:IComponentWithLoader<ILoaderData, IVulnerabilityListOptions> = {
  loader: vulnerabilitiesLoader,
  Component: () => {
    const i18n = useTranslation();
    const { getCustomerSetting } = useAccount();
    const searchParamsTuple = useSearchParams();

    const customerSecurityLevel = getCustomerSetting(Module.none, 'security-level', SecurityLevel.Unknown);

    const queryUtil = useMemo(() => new VulnerabilityControlQueryUtil(
      customerSecurityLevel,
      () => { },
      searchParamsTuple,
    ), [customerSecurityLevel, searchParamsTuple]);

    const filters = queryUtil.getFilters();

    const {
      options,
      topics,
    } = useLoaderData() as Awaited<ILoaderData>;

    const { data: pagedVulnerabilities } = useApiLoaderData<PagedResult<IVulnerability>, ILoaderData>(
      'vulnerabilities',
      (loaderData) => loaderData.pagedVulnerabilities,
      options,
    );

    const vulnerabilities = pagedVulnerabilities.items;

    const donutData = createSecurityLevelImplementationStats(vulnerabilities);
    const implDataRadar = createImplementationPercentRadarData(vulnerabilities, filters?.securityLevel);
    const implDataChart = createImplementationPercentStackedChartData(vulnerabilities, filters?.securityLevel);

    return (
      <>
        <Row>
          <Col md={12}>
            <VulnerabilityFilter
              customerSecurityLevel={customerSecurityLevel}
              queryUtil={queryUtil}
            />
          </Col>
        </Row>
        <>
          <div className="row">
            <div className="col-md-6 col-lg-5 sm-12">
              <div className="card flex-fill w-100">
                <div className="card-header">
                  Implementation percent
                </div>
                <div className="card-body pt-3 pb-0">
                  <ImplementationPercentRadarChart data={implDataRadar} topics={topics} />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7 sm-12">
              <div className="card flex-fill w-100">
                <div className="card-header">
                  Vulnerability status by topic
                </div>
                <div className="card-body pt-3 pb-0">
                  <ImplemenationPercentStackedChart data={implDataChart} topics={topics} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            { !filters?.securityLevel || containsSecurityLevel(filters.securityLevel, SecurityLevel.Basic)
              ? (
                <div className="col-md-4">
                  <div className="card flex-fill w-100">
                    <OverlayTrigger overlay={(
                      <Tooltip className="tooltip-sm">
                        { i18n.getString('security-level.basic.description') }
                      </Tooltip>
                    )}
                    >
                      <div className="card-header">
                        <span>
                          Minimum security
                          <Button className="tooltip-trigger right-icon icon">
                            <Icon name="info" />
                          </Button>
                        </span>
                      </div>
                    </OverlayTrigger>
                    <div className="card-body p-0 py-3">
                      <ImplementationPercentDonutChart
                        data={donutData.basic ?? []}
                      />
                    </div>
                  </div>
                </div>
              ) : null }
            { !filters?.securityLevel || containsSecurityLevel(filters.securityLevel, SecurityLevel.Improved)
              ? (
                <div className="col-md-4">
                  <div className="card flex-fill w-100">
                    <OverlayTrigger overlay={(
                      <Tooltip className="tooltip-sm">
                        { i18n.getString('security-level.improved.description') }
                      </Tooltip>
                    )}
                    >
                      <div className="card-header">
                        <span>
                          Standard security
                          <Button className="tooltip-trigger right-icon icon">
                            <Icon name="info" />
                          </Button>
                        </span>
                      </div>
                    </OverlayTrigger>
                    <div className="card-body p-0 py-3">
                      <ImplementationPercentDonutChart
                        data={donutData.improved ?? []}
                      />
                    </div>
                  </div>
                </div>
              ) : null }
            { !filters?.securityLevel || containsSecurityLevel(filters.securityLevel, SecurityLevel.Advanced)
              ? (
                <div className="col-md-4">
                  <div className="card flex-fill w-100">
                    <OverlayTrigger overlay={(
                      <Tooltip className="tooltip-sm">
                        { i18n.getString('security-level.advanced.description') }
                      </Tooltip>
                    )}
                    >
                      <div className="card-header">
                        <span>
                          Advanced security
                          <Button className="tooltip-trigger right-icon icon">
                            <Icon name="info" />
                          </Button>
                        </span>
                      </div>
                    </OverlayTrigger>
                    <div className="card-body p-0 py-3">
                      <ImplementationPercentDonutChart
                        data={donutData.advanced ?? []}
                      />
                    </div>
                  </div>
                </div>
              ) : null }
          </div>
        </>
      </>
    );
  },
};

export default VulnerabilitySummaryPage;
