import React from 'react';
import { Button } from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { useNavigate } from 'react-router-dom';

interface ICardCloseButtonAttributes {
  closeAction:() => void
}

export const CardCloseButton = (props: ICardCloseButtonAttributes) => {
  const { closeAction } = props;
  return (
    <div className="card-close-button">
      <Button variant="danger" size="sm" onClick={closeAction} title="Close">
        <Icon name="x" size="18" />
      </Button>
    </div>
  );
};

export const CardCloseBackButton = () => {
  const navigate = useNavigate();

  const closeAction = () => {
    navigate(-1);
  };

  return <CardCloseButton closeAction={closeAction} />;
};
