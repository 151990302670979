import {
  QueryClient, useQuery, useQueryClient, UseQueryOptions,
} from '@tanstack/react-query';
import axios, { AxiosHeaders, RawAxiosRequestHeaders } from 'axios';
import { useCallback, useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { PagedResult } from '../types/PagedResult';
import { IKeyValue, implementsKeyValue } from '../types/Types';

export enum Method {
  GET
}

const createQueryKey = (method:Method, path:string, dataAsString?:string|undefined) : string[] => {
  const key = ['GenericQuery', method.toString()].concat(path.split('/'));
  if (dataAsString) {
    key.push(dataAsString);
  }
  return key;
};

const queryKeyCache:Record<string, string[]> = {};

export const getMemoizedQueryKey = (method:Method, path:string, dataAsString?:string|undefined) : string[] => {
  const queryKey = createQueryKey(method, path, dataAsString);

  const cacheKey = JSON.stringify(queryKey);
  if (!queryKeyCache[cacheKey]) {
    queryKeyCache[cacheKey] = queryKey;
  }

  return queryKeyCache[cacheKey];
};

/**
 * Properties format is not compatible between client and server side queries.
 * Convert any IKeyValue items to the proper server side format before sending.
 */
export const convertKeyValuesToQuery = <TData, >(query:TData|undefined) => {
  if (!query) return query;

  const modifiedQuery:{[index:string]: unknown} = { ...query };
  Object.keys(modifiedQuery as {[index:string]: unknown}).forEach((property) => {
    const asArray = modifiedQuery[property] as unknown[];
    if (asArray?.length && implementsKeyValue(asArray[0])) {
      const modifiedProperty:{[index:string]: unknown} = {};
      asArray.forEach((p) => {
        const typed = p as IKeyValue;
        modifiedProperty[typed.key] = typed.value;
      });
      modifiedQuery[property] = modifiedProperty;
    }
  });
  return modifiedQuery;
};

const createQuery = <TResponse, TData>(
  key:string[],
  method:Method,
  path:string,
  data?:TData|undefined,
  headers?:RawAxiosRequestHeaders | AxiosHeaders,
) => ({
    queryKey: getMemoizedQueryKey(method, path, data ? JSON.stringify(data) : undefined),
    queryFn: async () => ((await axios.get<TResponse>(`/api/v1/${path}`, {
      paramsSerializer: {
        indexes: null,
      },
      params: convertKeyValuesToQuery(data),
      headers,
    })).data),
    enabled: true,
  });

/**
 * Fetch all pages from the backend.
 */
export const getAllPages = async <TResponse, TData>(
  path:string,
  data:TData|undefined = undefined,
  items:Array<TResponse>|undefined = undefined,
  page:number|undefined = undefined,
)
: Promise<TResponse[]> => {
  const aggregatedItems = items ?? [];

  const currentPage = page || 1;
  const { data: responseData } = await axios.get<PagedResult<TResponse>>(path, {
    params: {
      ...data,
      page: currentPage,
    },
  });

  if (responseData.pageCount > responseData.currentPage) {
    return getAllPages(path, data, aggregatedItems.concat(responseData.items), currentPage + 1);
  }
  return aggregatedItems.concat(responseData.items);
};

export interface IApiInterface<TData> {
  data:TData
}

export const useApi = <TResponse, TData = object|undefined>(
  path:string|undefined|null|false,
  data?:TData|undefined,
  queryOptions?:Partial<UseQueryOptions<unknown, unknown, TResponse>>|undefined,
  method?:Method|undefined,
  headers?:RawAxiosRequestHeaders | AxiosHeaders,
) => {
  const queryClient = useQueryClient();
  const dataAsString = useMemo(() => JSON.stringify(data), [data]);
  const queryKey = getMemoizedQueryKey(method ?? Method.GET, (!path ? undefined : path) ?? 'uninitialized', dataAsString);

  const query = useQuery({
    ...createQuery<TResponse, TData>(
      queryKey,
      method ?? Method.GET,
      (!path ? undefined : path) ?? 'uninitialized',
      data,
      headers,
    ),
    ...{
      ...queryOptions,
      enabled: (queryOptions?.enabled ?? true) && !!path,
      // Since we're using this method in loaders, to prefetching data, we need to set a stale time
      // a bit in the future to allow the renderer to consume this before we have to refetch.
      staleTime: 2000,
    } as UseQueryOptions<unknown, unknown, TResponse>,
  });

  const invalidator = useCallback(
    async () => queryClient.invalidateQueries({ queryKey }),
    [queryClient, queryKey],
  );

  return {
    data: query.data,
    path,
    invalidate: invalidator,
    cancel: () => queryClient.cancelQueries(query),
    result: query.data,
    isFetched: query.isFetched,
    isLoading: query.isLoading,
    error: query.error,
    status: query.status,
  };
};

/**
 * A convenience mehod to attach to data provided from a loader, with live hookup to the underlying
 * query. Allows a page to have loader which prefetches all data, deferring rendering until all
 * data is available, and the component to access the live query data as through the normal
 * `useApi` method.
 *
 * @param path
 * @param loaderDataResolver
 * @param data
 * @param queryOptions
 * @param method
 * @returns
 */
export const useApiLoaderData = <TResult, TLoaderData, TQueryParams = object|undefined>(
  path:string|undefined|null|false,
  loaderDataResolver:(loaderData:Awaited<TLoaderData>) => TResult,
  data?:TQueryParams|undefined,
  queryOptions?:Partial<UseQueryOptions<unknown, unknown, TResult>>|undefined,
  method?:Method|undefined,
) => {
  const queryResult = useApi(path, data, queryOptions, method);
  const loaderData = useLoaderData() as Awaited<TLoaderData>;

  return {
    data: queryResult.data ?? loaderDataResolver(loaderData),
    path: queryResult.path,
    invalidate: queryResult.invalidate,
    cancel: queryResult.cancel,
    result: queryResult.result,
    isFetched: queryResult.isFetched,
    isLoading: queryResult.isLoading,
    status: queryResult.status,
    error: queryResult.error,
  };
};

export const getOrFetchFromApi = async <TResponse, TData = object|undefined>(
  queryClient:QueryClient,
  path:string|undefined|false,
  data?:TData|undefined,
  options?: {
    retry?: number|boolean|undefined,
    forceRefresh?: boolean,
  },
) : Promise<TResponse> => {
  if (!path) {
    throw new Error('Unable to load route data');
  }

  const { forceRefresh, retry } = options ?? {};

  const dataAsString = JSON.stringify(data);
  const queryKey = createQueryKey(Method.GET, (!path ? undefined : path) ?? 'uninitialized', dataAsString);

  const result = (forceRefresh ? null : queryClient.getQueryData<TResponse>(queryKey))
    ?? queryClient.fetchQuery<TResponse>(
      {
        ...createQuery<TResponse, TData>(queryKey, Method.GET, path, data),
        retry,
        // Since we're using this method in loaders, to prefetching data, we need to set a stale time
        // a bit in the future to allow the renderer to consume this before we have to refetch.
        staleTime: 2000,
      },
    );
  return result;
};

export const useInvalidateQueries = (pathSuffix:string, method?:Method|undefined) => {
  const queryClient = useQueryClient();
  const queryKey = createQueryKey(method ?? Method.GET, pathSuffix);
  return () => queryClient.invalidateQueries({ queryKey });
};
