import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useStore } from 'zustand';
import { IAsset } from '../../types/AssetsTypes';
import { PageableColumnDefV8, TableCellDateFormattedV8 } from '../../common/table';
import { PagedResult } from '../../types/PagedResult';
import { useAssetTypeAsText } from '../../utils/TranslationUtils';
import { columnsToVisibilityState, useTableStoreV8 } from '../../common/table/TableStoreV8';
import {
  AssetType, assetTypesAsString, IAssetListOptions, IKeyValue,
} from '../../types/Types';
import { ClipboardCopy } from '../../components/ClipboardCopy';
import { TableFromPageable } from '../../common/table/TableFromPageable';
import { createPageableColumnHelper, IPagedTableFilter } from '../../common/table/PagedResultFilter';

/**
 * React table for showing assets. Note that it uses the generic ReactTable component.
 * @see ReactTable
 */
export const AssetsTable = ({
  pagedAssets,
  pagedQuery,
}:{
  pagedAssets:PagedResult<IAsset>,
  pagedQuery:IPagedTableFilter<IAssetListOptions>
}) => {
  const navigate = useNavigate();
  const assetTypeAsText = useAssetTypeAsText();

  const columnHelper = createPageableColumnHelper<IAsset & {securityAdvisoryIds?:string[]}>();

  const assetColumns = React.useMemo<PageableColumnDefV8<IAsset, unknown>[]>(() => {
    const columns = [
      {
        ...columnHelper.accessor('friendlyId', {
          header: 'Unique ID',
          cell: ({ getValue }) => {
            const value = getValue();
            return value
              ? (
                <ClipboardCopy>
                  {value}
                </ClipboardCopy>
              ) : null;
          },
        }),
        disableColumnDefault: true,
        customFilter: {
          sortPropertyName: 'friendlyId',
          filterPropertyName: 'assetFriendlyIds',
          filterFn: (values:string[]) => {
            if (pagedQuery.setQuery) {
              pagedQuery.setQuery({
                assetFriendlyIds: values,
              });
            }
          },
        },
      },
      {
        ...columnHelper.accessor('name', {
          header: 'Name',
          enableHiding: false,
          cell: ({ getValue }) => (
            <ClipboardCopy>
              {getValue()}
            </ClipboardCopy>
          ),
        }),
        customFilter: {
          filterPropertyName: 'name',
          filterFn: (values:string[]) => {
            if (pagedQuery.setQuery) {
              pagedQuery.setQuery({
                name: values.length ? values[0] : undefined,
              });
            }
          },
        },
      },
      {
        ...columnHelper.accessor('type', {
          header: 'Type',
          cell: ({ getValue }) => (
            <span>
              {assetTypeAsText(getValue())}
            </span>
          ),
        }),
        formatter: (v) => assetTypeAsText(v as AssetType, false),
        customFilter: {
          filterPropertyName: 'types',
          sortPropertyName: 'type',
          filterFn: (values:AssetType[]) => {
            if (pagedQuery.setQuery) {
              pagedQuery.setQuery({
                types: values,
              });
            }
          },
          selectOptions: assetTypesAsString,
          supportMultiSelect: true,
        },
      },
      columnHelper.accessor(
        'created',
        {
          header: 'Created',
          cell: ({ getValue }) => TableCellDateFormattedV8(getValue()),
          enableColumnFilter: false,
        },
        {
          filterPropertyName: 'created',
        },
      ),
      {
        ...columnHelper.accessor('properties', {
          header: 'Properties',
          cell: ({ getValue }) => (
            <ul>
              { getValue()?.map((p) => (
                <li key={p.key}>
                  {p.key}
                  =
                  {p.value}
                </li>
              ))}
            </ul>
          ),
          defaultHidden: true,
        }),
        customFilter: {
          filterPropertyName: 'properties',
          filterFn: (values:string[]) => {
            if (pagedQuery.setQuery) {
              pagedQuery.setQuery({
                properties: values.length ? values[0].split(',').reduce((acc, pair) => {
                  const [key, value] = pair.split('=');
                  if (key && value) {
                    acc.push({ key: key.trim(), value: value.trim() });
                  }
                  return acc;
                }, [] as IKeyValue[]) : undefined,
              });
            }
          },
          valuesFormatterFn: (values:IKeyValue[]) => (values ? values.map((v) => `${v.key}=${v.value}`, '').join(', ') : ''),
        },
      },
    ] as PageableColumnDefV8<IAsset, unknown>[];

    // Only add securityAdvisoryIds if filtered. If filtered, we know the
    // asset has the advisory and add that from the filter. We do not
    // expand this server side, due to the database cost.
    if (pagedQuery.pageableQuery.securityAdvisoryIds?.length) {
      columns.push({
        ...columnHelper.accessor(
          'securityAdvisoryIds',
          {
            header: 'Security Advisory',
            cell: () => {
              const advisoryIds = pagedQuery.pageableQuery.securityAdvisoryIds;
              return advisoryIds
                ? advisoryIds.map((advisoryId) => <ClipboardCopy key={advisoryId}>{advisoryId}</ClipboardCopy>)
                : null;
            },
            defaultHidden: true,
          },
          {
            filterPropertyName: 'securityAdvisoryIds',
            filterFn: (values: string[]) => {
              if (pagedQuery.appendQuery) {
                pagedQuery.appendQuery({
                  securityAdvisoryIds: values.length ? values : undefined,
                });
              }
            },
          },
        ),
      } as PageableColumnDefV8<IAsset, unknown>);
    }

    return columns;
  }, [assetTypeAsText, columnHelper, pagedQuery]);

  const onRowClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, object: IAsset) => {
    if (object.id) {
      if (e.button === 3 || e.ctrlKey) {
        window.open(`/asset/${object?.id}`, '_blank');
      } else {
        navigate(`/asset/${object?.id}`);
      }
    }
  };

  const { store: tableStore } = useTableStoreV8(
    'asset',
    {
      visibilityState: columnsToVisibilityState(assetColumns),
    },
  );

  const tableState = useStore(tableStore);

  return (
    <div>
      {
        pagedAssets
          ? (
            // Note that the id of the table below is used when saving filter
            // values to session storage. That means, if you change the id it
            // will clear all saved filters in session storage for all users.
            <TableFromPageable
              id="assets-table"
              state={tableState}
              columnDefs={assetColumns}
              pagedResult={pagedAssets}
              setPage={(page) => {
                if (pagedQuery.setPage != null) pagedQuery.setPage(page);
              }}
              pageSize={pagedQuery?.pageableQuery.pageSize ?? -1}
              resetFilters={() => { if (pagedQuery.reset) pagedQuery.reset(); }}
              filterValues={pagedQuery.pageableQuery ?? {}}
              isFiltered={pagedQuery.isFiltered ?? false}
              onRowClick={onRowClick}
              sorting={pagedQuery.sorting}
              setSorting={pagedQuery.setSorting}
            />
          )
          : <Spinner animation="border" className="mt-3" />
      }
    </div>
  );
};

export default AssetsTable;
