import { useState, useCallback } from 'react';

export const useClickWithoutDrag = (
  dragThreshold = 5,
) => {
  const [mouseDownPosition, setMouseDownPosition] = useState<{ x: number; y: number } | null>(null);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    setMouseDownPosition({ x: e.clientX, y: e.clientY });
  }, []);

  const getMouseUpHandler = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, onClick: () => void) => {
      const target = e.target as unknown as {nodeName:string};
      if (target.nodeName === 'A') return () => {};

      try {
        if (mouseDownPosition) {
          const deltaX = Math.abs(e.clientX - mouseDownPosition.x);
          const deltaY = Math.abs(e.clientY - mouseDownPosition.y);
          // If mouse was moved less than threshold, click
          if (deltaX < dragThreshold && deltaY < dragThreshold) {
            return onClick();
          }
        }
        return () => {};
      } finally {
        // Reset position
        setMouseDownPosition(null);
      }
    },
    [mouseDownPosition, dragThreshold],
  );

  return {
    onMouseDown: handleMouseDown,
    getMouseUpHandler,
  };
};
