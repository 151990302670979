import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ISecurityAdvisoryListOptions, ISecurityAdvisoryWithVulnerabilityCount } from '../../types/Types';
import { useApi } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import { SecurityAdvisoryTable } from '../assets/SecurityAdvisoryTable';
import { IPaginationMutableState } from '../../common/table/PaginationV8';
import ROUTES from '../../routing/Routes';

const options:ISecurityAdvisoryListOptions&IPaginationMutableState&{sortBy?:string} = {
  withOpenVulnerabilities: true,
  page: 1,
  pageSize: 5,
  sortBy: 'vulnerabilityCount desc',
};

export const SecurityAdvisoriesTile = () => {
  const navigate = useNavigate();

  const { data } = useApi<PagedResult<ISecurityAdvisoryWithVulnerabilityCount>>(
    'securityAdvisories',
    options,
  );

  return data
    ? (
      <>
        <SecurityAdvisoryTable
          data={data}
          pagedTableFilter={undefined}
          disableFilters
          disableColumnSelect
          disablePagination
          disableSorting
        />
        <Button
          onClick={() => navigate(ROUTES.assetsSecurityAdvisories.uri)}
        >
          Show all advisories
        </Button>
      </>
    )
    : <Spinner animation="border" />;
};
