import React, { useRef } from 'react';
import { QueryClient } from '@tanstack/react-query';
import {
  Card, Col, Row,
} from 'react-bootstrap';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import { ValidPageSizes } from '../../common/table/PaginationV8';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { PagedResult } from '../../types/PagedResult';
import { ISecurityAdvisoryListOptions, ISecurityAdvisoryWithVulnerabilityCount } from '../../types/Types';
import { Direction, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { SecurityAdvisoryTable } from './SecurityAdvisoryTable';

export const defaultOptions:ISecurityAdvisoryListOptions = {
  withOpenVulnerabilities: true,
};

export const SecurityAdvisoriesPage:IComponentWithLoader<
  PagedResult<ISecurityAdvisoryWithVulnerabilityCount>,
  never
  > = {
    loader: (
      queryClient:QueryClient,
      _,
      pageSize:ValidPageSizes,
    ): Promise<PagedResult<ISecurityAdvisoryWithVulnerabilityCount>> => (
      getOrFetchFromApi<PagedResult<ISecurityAdvisoryWithVulnerabilityCount>>(
        queryClient,
        'securityAdvisories',
        {
          ...defaultOptions,
          page: 1,
          pageSize,
        },
      )
    ),
    Component: () => {
      const pagedTableFilter = usePagedTableFilter<ISecurityAdvisoryListOptions>(
        'assets-security-advisory-page',
        defaultOptions,
        defaultOptions,
        [
          { property: 'vulnerabilityCount', direction: Direction.desc },
        ],
      );

      const { pageableQuery } = pagedTableFilter;

      const { data: pagedAdvisories } = useApiLoaderData<
        PagedResult<ISecurityAdvisoryWithVulnerabilityCount>,
        PagedResult<ISecurityAdvisoryWithVulnerabilityCount>
        >(
          'securityAdvisories',
          (loaderData) => loaderData,
          pageableQuery,
        );

      const scrollContainerRef = useRef(null);

      return (
        <Row>
          <Col>
            <Card className="fill-content">
              <Card.Header>
                <Card.Title>Assets</Card.Title>
              </Card.Header>
              <Card.Body ref={scrollContainerRef} className="overflow-auto" id="assets-card">
                <SecurityAdvisoryTable data={pagedAdvisories} pagedTableFilter={pagedTableFilter} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      );
    },
  };
