/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { IRisk, RiskStatus } from '../../types/RiskTypes';
import { getOpacityStyle } from './Utils';
import { useApi } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import { useTranslation } from '../../providers/TranslationProvider';
import { AllSignificances, ISeverityComponents } from '../vulnerabilities/Types';
import { severityAsCssClassName, asSeverity } from '../vulnerabilities/Utils';
import { useGetSignificanceAsText } from '../../utils/TranslationUtils';

export interface IMatrixCellProps {
  count: number,
  maxCount?: number,
  single?: boolean,
  backgroundClassName: string,
  onClick?: () => void,
  className?: string,
  children?: string|React.ReactNode
}

const RiskMatrixCell = ({
  backgroundClassName, count, maxCount, single, onClick, className, children,
}: IMatrixCellProps) => (
  <td
    className={`${backgroundClassName} ${className} text-white td-cell text-shadow`}
    style={{ position: 'relative' }}
    align="center"
  >
    { single && count === 1
      ? (
        <div className={`risk-matrix-cell-background ${backgroundClassName}`}>
          {children}
        </div>
      )
      : (
        <>
          <div
            className={`risk-matrix-cell-background ${backgroundClassName}`}
            style={getOpacityStyle(single, count, maxCount)}
          />
          <div className="table-cell">
            { onClick
              ? (
                <button
                  type="button"
                  onClick={onClick}
                >
                  { count > 0 ? count : '' }
                </button>
              )
              : <div>{ count > 0 ? count : '' }</div> }
          </div>
        </>
      )}
  </td>
);

interface IMatrixProps {
  getCount: (item:ISeverityComponents) => number,
  maxCount?: number,
  single?: boolean,
  compact?: boolean,
  onClick?: (item:ISeverityComponents) => void,
  cellClassName?: (item:ISeverityComponents) => string|undefined,
  showDetails?: boolean,
  className?: string|undefined,
  style?: React.CSSProperties|undefined
}

export const ProbabilityAndImpactMatrix = (props: IMatrixProps) => {
  const {
    getCount, maxCount, single, compact, onClick, cellClassName, showDetails, className, style,
  } = props;

  const i18n = useTranslation();

  const significanceAsText = useGetSignificanceAsText();

  const probabilities = [...AllSignificances];
  probabilities.reverse();

  const impacts = [...AllSignificances];

  return (
    <div>
      <Table className={`risk-matrix ${className}`} bordered style={style}>
        { compact
          ? null
          : (
            <thead>
              <tr className="align-top">
                <th>
                  <span className="float-end">Impact</span>
                  <br />
                  <span className="float-start">Probability</span>
                </th>
                <th>{ i18n.getString('significance.veryLow') }</th>
                <th>{ i18n.getString('significance.low') }</th>
                <th>{ i18n.getString('significance.medium') }</th>
                <th>{ i18n.getString('significance.high') }</th>
                <th>{ i18n.getString('significance.veryHigh') }</th>
              </tr>
            </thead>
          ) }
        <tbody>
          { probabilities.map((probability) => (
            <tr key={`p-${probability}`}>
              { compact ? null : <td>{ significanceAsText(probability) }</td> }
              { impacts.map((impact) => {
                const count = getCount({ probability, impact });
                return (
                  <RiskMatrixCell
                    key={`i-${impact}`}
                    single={single}
                    count={count}
                    onClick={onClick && count > 0
                      ? () => { if (onClick) onClick({ probability, impact }); }
                      : undefined}
                    maxCount={maxCount}
                    backgroundClassName={
                      severityAsCssClassName(asSeverity({ probability, impact }))
                    }
                    className={`${cellClassName ? cellClassName({ probability, impact }) ?? '' : ''}`}
                  >
                    <div className="small pt-1 px-2 text-nowrap diagonal-separator">
                      { showDetails ? (
                        <>
                          <div className="text-end">
                            {significanceAsText(impact)}
                          </div>

                          <div className="text-start">
                            {significanceAsText(probability)}
                          </div>
                        </>
                      ) : null }
                    </div>
                  </RiskMatrixCell>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

interface IRiskSummaryMatrixProps {
  risks: IRisk[]|undefined,
  showClosed?: boolean|undefined,
  onClick?: (item:ISeverityComponents) => void,
  cellClassName?: (item:ISeverityComponents) => string,
}

const getSignificanceKey = ({ impact, probability }:ISeverityComponents) => (
  `${probability}::${impact}`
);

/**
 * The risk summary matrix shows a risk severity matrix for all provided risks, or risks fetched
 * from the API if risks are undefined.
 *
 * @param props : IRiskSummaryMatrixProps
 * @returns The RiskSummaryMatrix compoenent
 */
export const RiskSummaryMatrix = (props: IRiskSummaryMatrixProps) => {
  const {
    risks: inputRisks, showClosed, onClick, cellClassName,
  } = props;

  const { data: fetchRiskPages } = useApi<PagedResult<IRisk>>(
    !inputRisks && 'risks',
  );

  const risks = inputRisks ?? fetchRiskPages?.items;

  const counts = useMemo(() => {
    const mCounts:Record<string, number> = {};
    risks?.forEach((risk) => {
      if (!showClosed && risk.status === RiskStatus.Closed) {
        return;
      }
      const key = getSignificanceKey(risk);

      if (!mCounts[key]) {
        mCounts[key] = 1;
      } else {
        mCounts[key] += 1;
      }
    });
    return mCounts;
  }, [risks, showClosed]);

  const maxCount = Math.max(...Object.values(counts));
  const getCount = (item:ISeverityComponents) => (
    counts[getSignificanceKey(item)] ?? 0
  );

  return risks
    ? (
      <ProbabilityAndImpactMatrix
        onClick={onClick}
        getCount={getCount}
        maxCount={maxCount}
        single={false}
        cellClassName={cellClassName}
      />
    )
    : <Spinner animation="border" />;
};
